<template>
  <ElFormItem :prop="prop" :rules="rules" :label="c_label" class="u-col4 nutrition-input">
    <ElInput @compositionend.native.lazy="toHankakuForInput" :disabled="disabled"
              v-model="c_value" :placeholder="$t(placeholder)">
    </ElInput>
  </ElFormItem>
</template>

<style lang="scss" scoped>
.nutrition-input {
  ::v-deep .el-form-item__label {
    letter-spacing: 0;
  }
}
</style>

<script>
  import StringUtils from "@/utils/string-utils";
  import {ValidatorRules} from '@/entities/ingredient-nutrition-entity';

  import _get from 'lodash/get';
  import _isArray from 'lodash/isArray';

  export default {
    props: {
      label: String,
      unit: {
        type: String,
        required: false,
      },
      value: null,
      prop: {
        default: null
      },
      placeholder: {
        type: String,
        default: '例）100'
      },
      disabled: {
        type: Boolean,
        default: false
      },
    },
    computed: {
      c_value: {
        set(val) { this.$emit('input', val); },
        get() { return this.value; }
      },
      c_label: {
        get() { return this.unit ? this.$t(this.label) + `(${this.unit})` : this.$t(this.label); }
      }
    },
    data() {
      return {
        rules: ValidatorRules.nutrition,
      };
    },
    mounted () {
      if (this.$children[0].elForm.rules) {
        const existingRule = _get(this.$children[0].elForm.rules, this.prop);
        if (existingRule && _isArray(existingRule)) {
          this.rules = existingRule.concat(...ValidatorRules.nutrition);
        }
      }
    },
    methods: {
      toHankakuForInput(evt) {
        // this.c_value = StringUtils.toHankaku(evt.target.value + evt.data);
        this.c_value = StringUtils.toHankaku(evt.target.value);
      }
    }
  }
</script>
